// import { useEffect } from 'react'
import '../../src/zocratez_web.css'
// import ReactGa from 'react-ga';
export default function LandingPage() {
    // useEffect(()=>{
    //     ReactGa.event({
    //         category: 'OnLoad',
    //         action: 'Landing Page Visited'
    //       });
    // })

    // const handleAppStore=()=>{
    //     ReactGa.event({
    //         category: 'AppStore Click',
    //         action: 'Appstore visited'
    //       })
    // }
    // const handleGooglePlay=()=>{
    //     ReactGa.event({
    //         category: 'Play Store Click',
    //         action: 'Playstore visited'
    //       })
    // }



    return (
        <>
            <section class="section1__section1 layout">
                <div class="section1__flex0 layout3">
                    <div class="item">
                        <div class="section1__group layout4">
                            <div class="section1__flex layout2">
                                <div class="section1__flex layout3">
                                    <div class="section1__block layout">
                                        <div class="section1__flex1 layout">
                                            <div class="item">
                                                <div class="section1__flex layout1">
                                                    <img src={require("../images/hit-logo.png")} style={{ width: "237px" }} alt="" class="section1__image layout" />
                                                    <div class="section1__flex layout">
                                                        <h1 class="section1__hero_title layout">基本英単語を楽しく最速で学ぼう</h1>
                                                        <h1 class="section1__hero_title1 layout">A fun way to learn English!</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="spacer"></div>
                                            <img style={{ display: "inline-block", alignSelf: "flex-end" }} class="section1__image1" src={require("../images/edf296974519d5e3c684421d8e0fd9db.png")} alt="hero-01@2x 1" />
                                        </div>
                                    </div>
                                    <div class="item">
                                        <div class="section1__block1 layout">
                                            <h1 class="section1__hero_title11 layout">1日1分から。自分のペースで楽しく学習</h1>
                                            <h2 class="section1__medium_title layout">Zocratezを使って、無料で基本英単語を習得しよう</h2>
                                            <div class="section1__flex2 layout">
                                                <div class="item">
                                                    {/* backgroundImage: url("https://via.placeholder.com/500")  */}
                                                    <div class="section1__block2 layout">
                                                        <img

                                                            style={{ width: "250px", transform: "scaleX(1.1)", marginRight: "30px", cursor: "pointer" }}
                                                            src={require("../images/appleLogo.png")} alt=""
                                                            class="section1__image6 layout"
                                                            onClick={() => window.location = 'https://apps.apple.com/us/app/zocratez/id1625087483'}
                                                        />
                                                    </div>
                                                </div>

                                                <div class="item1">
                                                    <div
                                                        class="ssection1__block2 layout" >

                                                        <img style={{ width: "250px", marginLeft: "30px", cursor: "pointer" }}
                                                            src={require("../images/googlePlay.png")} alt=""
                                                            class="section1__image6 layout"
                                                            onClick={() => window.location = 'https://play.google.com/store/apps/details?id=jp.co.dhj.zocratez'}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* 66655d6dc2416cb90313afb7fc073a6b.png */}
                                <div class="section1__flex31 layout">
                                    <div class="section1__flex layout3">
                                        <div class="section1__flex layout3">
                                            <div class="section1__block3 layout">
                                                <img style={{ display: "inline-block", alignSelf: "flex-end" }} src={require("../images/8f13dda4805edebea32656b6f2b35541.png")} alt="" class="section1__image15a layout" />
                                                <div class="item1">
                                                    <div class="section1__flex21 layout">
                                                        <h1 class="section1__hero_title2 layout">基本英単語を楽しく暗記</h1>
                                                        <h1 class="section1__hero_title21 layout">マスターリスト</h1>
                                                        <h1 class="section1__big_title layout">やさしい順、よく出る順に効率よく学ぶ</h1>
                                                        <h1 class="section1__hero_title22 layout">プレイリスト</h1>
                                                        <h1 class="section1__big_title1 layout">好きなトピックの関連単語を一気に学ぶ</h1>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="section1__block6 layout">
                                                <div class="item">
                                                    <h1 class="section1__hero_title3_box layout1">
                                                        <div class="section1__hero_title3">
                                                            <span class="section1__hero_title3_span0">1回のセッション「</span><span class="section1__hero_title3_span1">ヒトクチ</span><span class="section1__hero_title3_span2"
                                                            >」では次にあなたが学ぶべき12単語が出題<br /><br />ちょっとした隙間時間を活用して継続的に学ぼう<br
                                                                /></span>
                                                        </div>
                                                    </h1>
                                                </div>
                                                <img style={{ display: "inline-block", alignSelf: "flex-end" }} src={require("../images/8236c71fe8319da245b536c2257133b0.png")} alt="" class="section1__image15b layout1" />
                                            </div>

                                            <div class="section1__flex layout4">
                                                <div class="section1__block4 layout">
                                                   
                                                </div>
                                                <div class="section1__block5 layout">
                                                    <img style={{ display: "inline-block", alignSelf: "flex-end" }} src={require("../images/05c47da5096c909ef4356151ccd1ffc0.png")} alt="" class="section1__image15c layout2" />
                                                    <div class="item1">
                                                        <div class="section1__flex11 layout">
                                                            <h1 class="section1__hero_title4 layout">ヒントを見て楽しく解答</h1>
                                                            <h1 class="section1__big_title11_box layout">
                                                                <div class="section1__big_title11">
                                                                    <span class="section1__big_title11_span0">知らない単語には「</span><span class="section1__big_title11_span1">ヒントボタン</span><span class="section1__big_title11_span2"
                                                                    >」で確認するのがおすすめ<br />画像や関連説明から推測して解答しよう</span>
                                                                </div>
                                                            </h1>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="section1__block6 layout">
                                            <div class="item">
                                                <h1 class="section1__hero_title3_box layout1">
                                                    <div class="section1__hero_title3">
                                                        <span class="section1__hero_title3_span0">マスターリストの約100単語ごとに「</span><span class="section1__hero_title3_span1">レベル</span><span class="section1__hero_title3_span2"
                                                        >」が設定されている<br /><br />Zocratēz(ゾクラティズ)のレベル画像が現れ、次のレベルに挑戦できる</span>
                                                    </div>
                                                </h1>
                                            </div>
                                            <img style={{ display: "inline-block", alignSelf: "flex-end" }} src={require("../images/c40e246ce431f19ee7a0725ff0316a22.png")} alt="" class="section1__image16 layout" />
                                        </div>
                                    </div>
                                    <div class="section1__flex44 layout2">
                                        <div class="item3" style={{ marginRight: "1%" }}>
                                            <img src={require("../images/ff5c4365227b26873978d1c89543da00.png")} alt="" class="section1__image17 layout" />
                                        </div>
                                        <div class="spacer"></div>
                                        <div class="item4">
                                            <div class="section1__flex4 layout1">
                                                <div class="item5 cstm-wid">
                                                    <h3 class="section1__subtitle cstm-MM layout" >© 2022 Zocratez</h3>
                                                </div>
                                                <div class="spacer1"></div>
                                                <div class="item6" style={{ display: "inline-flex", marginTop: "3px" }}>
                                                    <h3 class="section1__subtitle1 layout" style={{ marginLeft: "20px" }}><a href="https://zocratez.com/privacypolicy/jp" >プライバシーポリシー 　</a></h3>&nbsp;
                                                    <h3 class="section1__subtitle1 layout cstm-M"><a href="https://zocratez.com/terms/jp"  >利用規約</a></h3>&nbsp;
                                                    <h3 class="section1__subtitle1 layout cstm-M"><a href="https://zocratez.com/faq/jp"  >コンタクト</a>　</h3>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>


        </>
    )
}