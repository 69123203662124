
export default function Privacypolicy(){
    return(
        <>
        <div className="mx-4 my-4 flex">
        <div >プライバシーポリシー</div>


        <div>
        DHJ株式会社（以下、「当社」といいます。）は、お客様の個人情報の保護について、次のとおりプライバシーポリシー（以下「本ポリシー」といいます。）を定め、本ポリシーに基づき適正に個人情報を取り扱います。</div>
        <div>なお、本ポリシーで使用する用語の意味は、個人情報の保護に関する法律（以下「個人情報保護法」といいます。）に準拠するものとします。</div><br />
        
        <div>基本方針</div>
        
        <div>当社は、個人情報の重要性を認識し、個人情報を保護することが社会的責務であると考え、個人情報に関する法令及び社内規程等を遵守し、当社で取扱う個人情報の取得、利用、管理を適正に行います。
        </div>
        
        <div>適用範囲</div>
        本プライバシーポリシーは、当社が提供するサービス（以下、「本サービス」といいます。）において、お客様の個人情報もしくはそれに準ずる情報を取り扱う際に、当社が遵守する方針を示したものです
        
        <div><br />
        取得する個人情報の項目</div>
        
        <div>
        当社は、お客様に関する次に掲げる個人情報を取得します。</div>
        
        <ul className="pr-list">
                <li>（１） 本人確認に関する情報
メールアドレス、アカウントのID及びパスワード、SNSアカウント情報、電話番号、学年、居住する国及び都道府県等</li>
        
        
        <li>（２） サービスのご利用に際して取得する情報
クッキー（Cookie）ID、localStorage等のオンライン上の識別子、閲覧履歴その他サービスの利用状況（アクセスログ、IPアドレス、ブラウザ情報、ブラウザの言語設定等を含みます）等</li> 
        <li>（３） その他の情報 <br />
お客様から当社へのお問い合わせ・ご連絡等に関する情報等</li>
</ul>
        
       <div>個人情報の利用目的</div>
       <div>当社は、お客様からご提供いただく情報を、以下の目的の範囲内において取得及び利用します。</div>
       <div>（１） 本サービスの提供のため</div>
     <div>    （２）本サービスに関するご案内、お問い合わせ等への対応のため</div>
     <div>（３）本サービスのログイン等におけるご本人確認のため</div>
     <div>（４） 本サービスの利用規約等に違反する行為への対応のため</div>
     <div>（５）本サービスに関する規約等の変更などを通知するため</div>
     <div> （６）本サービスの改善、新サービスの開発等に役立てるため</div>
     <div> （７）本サービスの保守、管理のため</div>
     <div>（８）当社または第三者の媒体において、お客様の属性、居住地、利用履歴の分析に基づく興味及び関心等に合わせ、当社または第三者の商品等及びサービスの提供、勧誘、広告その他のマーケティングをするため</div>
     <div>   （９）本サービスに関連して、個別を識別できない形式に加工した統計データを作成するため</div>
     <div>（１０）本ポリシー記載の方法による、第三者に対する提供</div>
     <div>   （１１）その他、上記利用目的に付随する目的のため</div><br />
        
        <div>個人情報の管理</div>
        <div>本サービスは、個人情報の正確性及び安全確保のために、セキュリティ対策を徹底し、個人情報の漏洩、改ざん、不正アクセスなどの危険については、必要かつ適切なレベルの安全対策を実施します。</div>
        <div>本サービスは、第三者に重要な情報を読み取られたり、改ざんされたりすることを防ぐために、SSL/TSLによる暗号化を採用しております。</div><br />
        
        
        
        <div>個人情報の第三者提供</div>
        
        <div>当サイトは、以下を含む正当な理由がある場合を除き、個人情報を第三者に提供することはありません。</div>
        
        <div>（１）本人の同意がある場合</div>
        <div>（２）法令に基づく場合</div>
        <div>（３）人の生命、身体又は財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</div>
        <div>（４） 公衆衛生の向上又は児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</div>
        <div>（５）国の機関もしくは地方公共団体又はその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</div>
        
        <div >本サービスでは、利用目的の達成に必要な範囲内において、他の事業者へ個人情報を委託することがあります。</div><br />
        
       
        <div>外国にある第三者への個人情報の提供</div>
        
        <div >当社は、以下の場合において、外国（本邦の域外にある国または地域をいいます）にある第三者に個人情報を提供することがあります。
</div><br />
        
        
        <div>１．個人情報取扱業務を外部委託する場合</div>
        <ul>
                <li>
                当社は、本サービスの提供にかかるシステムの保守、改修等の業務（以下「保守業務」といいます）を外国の第三者に委託しており、取得した個人情報のうち、保守業務の委託に必要な情報項目を、保守業務の遂行を目的として、当該第三者に提供することがあります。この場合、当社は、当該委託先に対して必要かつ適切な監督を行います。
                </li>
                <li>
                提供先の国名及び提供先の外国の個人情報保護制度については、下記のとおりです。
                </li>
                
          

        </ul>
        <table className="table-bordered pr-table pr-margin-left" >
                <tr>
               <td className="pr-table">提供先の国名</td>   
               <td className="pr-table">提供先の外国の個人情報保護制度</td>
                </tr>

                <tr>
                        <td className="pr-table">インド</td>
                        <td className="pr-table"><a href="https://www.ppc.go.jp/files/pdf/india_report.pdf" className="pr-href">https://www.ppc.go.jp/files/pdf/india_report.pdf</a>
</td>
                </tr>
        </table>
       <br />
        
<div>
当社は、次に定めるとおり開示等の請求（利用目的の通知、個人情報又は第三者提供記録の開示、訂正・追加・削除、利用の停止・第三者提供の停止の請求をいいます。）に対応します。
</div>

<div> （１） 利用目的の通知又は個人情報若しくは第三者提供記録の開示
お客様は、当社に対し、（４）に定める手続に従い、個人情報保護法において認められる範囲内において、利用目的の通知を求め、又は、個人情報若しくは第三者提供記録の開示を請求することができます。ただし、当社は、次の各号のいずれかに該当する場合は、開示を行わないことがあります。</div>

<ul className="pr-list">
  <li>➀ 開示することでご本人様又は第三者の生命、身体、財産その他の権利利益を害するおそれがある場合</li>   
  <li>➁ 開示することで当社の業務の適正な実施に著しい支障を及ぼすおそれがある場合</li>  
  <li>➂ 開示することが法令に違反することとなる場合</li> 
  <li>➃ 開示の請求がご本人様からであることが確認できない場合</li>
</ul>

<div>
なお、利用目的の通知又は個人情報若しくは第三者提供記録の開示を請求される場合には、（４）に定める開示手数料をお支払いいただきます。 
</div> <br />  


<div>（２）訂正・追加・削除</div>
<div>お客様は、（４）に定める手続に従って、個人情報の訂正・追加・削除を請求することができます。その場合、当社は、利用目的の達成に必要な範囲内で遅滞なく調査を行い、その結果に基づき、個人情報保護法において認められる範囲内において、当該個人情報の訂正・追加・削除を行います。</div>
<br />
<div>    （３） 利用の停止又は第三者提供の停止</div>
 <div>お客様は、（４）に定める手続に従って、個人情報の利用の停止又は第三者提供の停止を請求することができます。その場合、当社は、個人情報保護法に従って適切に対応致します。</div>
<br />
<div>（４）手続</div>
<div>開示等の請求を行う場合、下記窓口までご一報ください。当社より、指定の請求書、本人確認書類等、必要な書類についてご案内をさせていただきます。また、利用目的の通知及び個人情報又は第三者提供記録の開示については、1回の請求につき1,000円の手数料をいただきます。当該手数料につきましては、請求書記載の振込先までお振込みいただく方法によりお支払いいただきます。なお、ご請求を検討した結果、不開示及び利用目的の非通知との結論に至った場合についても、同額の手数料をいただきます。</div><br />

<div>個人情報お問合わせ窓口</div>
<div>DHJ株式会社</div>
<div>住所：東京都江東区有明三丁目7-26有明フロンティアビルB棟9階</div>
<div>担当者：中山嘉貴</div>
<div>連絡先： <a href="https://zocratez.com/contact">こちらのフォーム</a>  より入力してください</div><br />

<div>個人情報取扱事業者の名称</div>
<div>DHJ株式会社</div>
<div>本社所在地：東京都江東区有明三丁目7-26有明フロンティアビルB棟9階</div>
<div>代表者氏名：中山嘉貴</div>
<div><a href="https://zocratez.com/contact/">https://zocratez.com/contact/</a></div><br />


<div>Cookie（クッキー）その他の技術の利用</div>
<div>本サービスは、Cookie（クッキー）及びこれに類する技術を利用することがあります。これらの技術は、本サービスの利用状況等の把握に役立ち、本サービスの改善等につながります。</div><br />

<div>利用者はCookie（クッキー）を無効にすることで収集を拒否することができます。ただし、Cookie（クッキー）を無効にした場合、本サービスの一部の機能が正しく動作しない場合があります。</div><br />
<div>アクセス解析</div>

<div>
本サービスでは、サイトの分析と改善のためにGoogle アナリティクスを利用することがあります。当該データの収集は個人情報として使用又は認識できる情報ではありません。Googleアナリティクスで当該データが収集、処理される仕組みにつきましては、下記をご確認下さい。
</div>
<div> <a href="https://policies.google.com/technologies/partner-sites?hl=ja" className="pr-href-break " >https://policies.google.com/technologies/partner-sites?hl=ja</a></div><br />
<div>広告配信</div>
<div>本サービスでは、広告配信のためにGoogle広告、Google AdMobを使用しております。
本サービスでは、広告配信を目的として以下の広告配信事業者がご利用者の情報を自動取得する場合がございます。取得する情報、利用目的、第三者への提供等につきましては、以下の広告配信事業者のアプリケーション・プライバシーポリシーのリンクよりご確認ください。
</div><br />

<div>AdMob（Google Inc.）</div>
<div> <a href="http://www.google.com/intl/ja/policies/technologies/ads/" className="pr-href-break">http://www.google.com/intl/ja/policies/technologies/ads/</a> </div><br />

<div>Google 広告に関するポリシー</div>
<div> <a href="https://policies.google.com/technologies/ads?hl=ja"  className="pr-href-break-ja ">https://policies.google.com/technologies/ads?hl=ja</a> </div><br />

<div>本プライバシーポリシーの変更</div>
<div>当社は、本プライバシーポリシーの内容を適宜見直し、その改善に努めます。</div>
<div>必要に応じて、本プライバシーポリシーを事前の予告なく変更することがあります。</div><br />

<div>2022年8月1日　制定</div><br />
</div>
        </>

    )}