import { useEffect } from "react"

export function FAQ(){
    
    useEffect(()=>{
        window.location.replace('https://tayori.com/faq/f97b03f96face798de9ffbe0244e974d757136fe/');
    },[])
    return(
        <>
         </>
    )
}