export function Terms() {
    return (
        <>

            <div className="mx-4 my-4 flex">

                <div className="terms-bold">Zocratēz利用規約</div>
                <div>この規約（以下「本規約」といいます。）は、DHJ株式会社（以下「当社」といいます。）が提供するすべての
                    Zocratez製品およびサービス（以下「本サービス」といいます。）の利用に関する条件を、本サービスを利用するお
                    客様（以下「お客様」といいます。）と当社との間で定めるものです。</div>
                <div className="terms-bold">1. 定義 </div>
                <div>
                本規約では、以下の用語を使用します。 
                    <div>1.1. 「コンテンツ」とは、学習問題、語句、画像、文章、音声、動画、数式、音楽、ソフトウェア、プログラム、コードその他の情報のことをいいます。 
                    </div><div>
                    1.2. 「本コンテンツ」とは、本サービスを通じてアクセスすることができるコンテンツのことをいいます。  </div>
                    <div>1.3. 「個別利用条件」とは、本サービスに関して、本規約とは別に「規約」、「ガイドライン」、「ポリシー」等の名称で当社が配布または掲示している条件のことをいいます。</div>
                    <div>１．４．本規約の内容と、前項の個別利用条件の内容が異なる場合は、本規約の規定が優先して適用されるものとします。</div>
                    <div className="terms-bold">
                    2. 規約への同意
                    </div>
                    <div>2.1. お客様は、本規約の定めに従って本サービスを利用しなければなりません。お客様は、本規約に同意をしない限り本サービスを利用できません。</div>
                    <div>2.2. お客様が未成年者である場合は、親権者等の法定代理人の同意を得たうえで本サービスを利用してください。また、お客様が本サービスを事業者のために利用する場合は、当該事業者も本規約に同意したうえで本サービスを利用してください。  </div>
                    <div>2.3. 本サービスにおいて個別利用条件がある場合、お客様は、本規約のほか個別利用条件の定めにも従って本サービスを利用しなければなりません。</div>
                </div>
                <div className="terms-bold">3. 規約の変更</div>
                <div>
                当社は、当社が必要と判断する場合、本サービスの目的の範囲内で、本規約を変更することができます。その場合、当社は、変更後の本規約の内容および効力発生日を、本サービスもしくは当社ウェブサイトに表示し、または当社が定める方法によりお客様に通知することでお客様に周知します。変更後の本規約は、効力発生日から効力を生じるものとします。
                </div>
                <div className="terms-bold">
                4. アカウント
                </div>
                <div>
                    <div>
                    4.1. 本サービスの利用に際して、情報を登録のうえ、アカウントの作成が必要となる場合があります。この場合、お客様は、真実、正確かつ完全な情報を登録しなければならず、常に最新の情報となるよう修正しなければなりません。
                        </div>
                    <div>4.2. お客様は、本サービスの利用に際して認証情報を登録する場合、これを不正に利用されないようご自身の責任で厳重に管理しなければなりません。当社は、登録された認証情報を利用して行われた一切の行為を、お客様ご本人の行為とみなすことができます。</div>
                    <div>4.3. 本サービスに登録したお客様は、いつでもアカウントを削除して退会することができます。</div>
                    <div>4.4. 当社は、最終のアクセスから１年間以上経過しているアカウントを、あらかじめお客様に通知することなく削除することができます。</div>
                    <div>4.5. お客様の本サービスにおけるすべての利用権は、理由を問わず、アカウントが削除された時点で消滅します。お客様が誤ってアカウントを削除した場合であっても、アカウントの復旧はできませんのでご注意ください。</div>
                    <div>4.6. 本サービスのアカウントは、お客様に一身専属的に帰属します。お客様の本サービスにおけるすべての利用権は、第三者に譲渡、貸与その他の処分または相続させることはできません。</div>
                    <div>４．７．当社は、お客様が以下の各号のいずれかの事由に該当する場合は、登録及び再登録を拒否することがあり、またその理由について一切開示義務を負いません。</div>
                    <div>(1) 当社に提供した登録事項の全部または一部につき虚偽、誤記または記載漏れがあった場合</div>
                    <div>(2) 未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合</div>
                    <div>(3) 反社会的勢力等（本規約において、暴力団、暴力団員、反社会的勢力、その他これに準ずる者を意味します。）である、または反社会的勢力の維持、運営もしくは経営に協力もしくは関与する等、反社会的勢力等と何らかの交流もしくは関与を行っていると当社が判断した場合</div>
                    <div>(4) 過去当社との契約に違反した者またはその関係者であると当社が判断した場合</div>
                    <div>(5) 14.2.に定める措置を受けたことがある場合</div>
                    <div>(6) その他、登録が適当でないと当社が判断した場合</div>
                </div>
                <div className="terms-bold">
                5. プライバシー</div>
                <div>
                5.1. 当社は、お客様のプライバシーを尊重しています。
                </div>
                <div>

                5.2. 当社は、お客様のプライバシー情報と個人情報を、プライバシーポリシーに従って適切に取り扱います。
                </div>
                <div>

                5.3. 当社は、お客様から収集した情報を安全に管理するため、セキュリティに最大限の注意を払っています。
                </div>
                <div className="terms-bold">
                6. サービスの提供
                </div>
                <div>
                6.1. お客様は、本サービスを利用するにあたり、必要なパーソナルコンピュータ、スマートフォン、タブレット端末その他の通信機器、オペレーションシステム、通信手段および電力等を、お客様の費用と責任で用意しなければなりません。</div>
                <div>
                6.2. 当社は、本サービスの全部または一部を、学年、地域、本人確認の有無、登録情報の有無、その他、当社が必要と判断する条件を満たしたお客様に限定して提供することができます。</div>
                <div>6.3. 当社は、当社が必要と判断する場合、あらかじめお客様に通知することなく、いつでも、本サービスの全部または一部の内容を変更することができます。</div>
                <div>6.4. 当社は、以下のいずれかに該当する場合、あらかじめお客様に通知することなく、本サービスの全部または一部の提供を中止することができます。</div>
                <div>(1) システムのメンテナンスまたは修理の実施を行う場合</div>
                <div>(2) 火災・停電等の事故、天災、戦争、暴動、労働争議等の不可抗力により、本サービスの提供ができなくなった場合</div>
                <div>(3) システムの障害が発生した場合またはシステムに負荷が集中した場合</div>
                <div>(4) お客様または第三者の安全を確保する場合または公共の利益のために緊急を要する場合</div>
                <div>(5) (1)から(4)までに定めるもののほか、当社が必要と合理的に判断した場合</div>
                <div>6.5. 当社は、当社の都合により、本サービスの提供を終了することができます。この場合、当社は、お客様に事前に通知するものとします。</div>
                <div className="terms-bold">7. 広告表示</div>
                <div>当社は、本サービスに当社または第三者の広告を掲載することができます。
当社は、お客様に対し有料で当社または第三者の広告の全部または一部を非表示にするサービスを提供することができます。
                </div>
                <div className="terms-bold">8. 当社以外の第三者のサービス</div>
                <div>本サービスは、当社以外の第三者が提供するサービスまたはコンテンツを含む場合があります。かかるサービスまたはコンテンツに対する責任は、これを提供する第三者が負います。また、かかるサービスまたはコンテンツには、これを提供する第三者が定める利用規約その他の条件が適用されることがあります。
                </div>
                <div className="terms-bold">9. コンテンツ</div>
                <div>
                9.1. 当社は、当社が提供する本コンテンツについて、お客様に対し、譲渡および再許諾できず、非独占的な、本サービスの利用を唯一の目的とする利用権を付与します。</div>
                <div>
                9.2. お客様は、利用料、利用期間その他の利用条件が別途定められた本コンテンツを利用する場合、かかる利用条件に従うものとします。本サービスの画面上で「サブスクライブ」、「購入」、「販売」等の表示がされている場合であっても、当社がお客様に対し提供する本コンテンツに関する知的財産権その他の権利はお客様に移転せず、お客様には、上記の利用権のみが付与されます。</div>
                <div>
                9.3. お客様は、本コンテンツを、本サービスが予定している利用態様を超えて利用(複製、送信、転載、改変を含みます。) </div>

                <div className="terms-bold">
                10. 有料サービスの提供
                </div>
                <div>
                10.1. お客様は、有料で、または当社所定の条件で、当社が指定する特定の本コンテンツや機能などに関し、一定期間利用するために一定の対価を支払うサービス（以下「有料サービス」といいます。）の提供を受けることができます。お客様は、本サービスを販売するアプリストア運営事業者との間で締結された契約または適用のある利用規約等に従い、アプリストアの運営事業者に利用料金を支払うものとします。</div>
                <div>
                10.2. 有料サービスの利用料金は、お客様と当該有料サービスを販売するアプリストア運営事業者との間での契約に従い、お客様が当該アプリストア運営事業者に対して直接支払うものとします。</div>
                <div>
                10.3. 有料サービスの利用料金の支払いに関する問い合わせや、支払った利用料金の返金の要請等については、当該有料サービスを購入したアプリストア運営事業者にお客様より直接連絡をとるものとします。当社はお客様から有料サービスの利用料金の支払いに関する問い合わせや支払った利用料金の返金の要請を受けても一切応じられません。</div>
<div>
10.4. 当社は、6.3.の定めにより本サービスの全部または一部を変更した場合であっても、本サービスの変更を理由とする利用料金の返金には応じないものとします。
</div>
<div>
10.5. 当社は、6.4.の定めまたはその他の理由により当社が本サービスの全部または一部を停止した場合、本サービスが停止されていた期間の利用料金の返金には応じないものとします。
</div>
                <div className="terms-bold">
                11. 禁止事項

                </div><div>
                当社は、本サービスに関するお客様による以下の行為を禁止します。 </div>
               <div> 11.1. 法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為</div>
                <div>11.2. 公の秩序または善良の風俗に反するおそれのある行為</div>
                <div>11.3. 当社または第三者の著作権、商標権、特許権等の知的財産権、名誉権、プライバシー権、その他法令上または契約上の権利を侵害する行為</div>
                <div>11.4. 過度に暴力的な表現、露骨な性的表現、児童ポルノ・児童虐待に相当する表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引または助長する表現、その他反社会的な内容を含み他人に不快感を与える表現を、他のお客様と共有する行為 </div>
                <div>11.5. 当社または第三者になりすます行為または意図的に虚偽の情報を流布させる行為 </div>
                <div>11.6. 同一または類似のコンテンツを不特定多数のお客様と共有する行為（当社の認めたものを除きます。）、その
                    他当社がスパムと判断する行為 </div>
                <div>11.7. 当社が定める方法以外の方法で、本サービスまたは本コンテンツの利用権を、現金、財物その他の経済上の利
                    益と交換する行為 </div>
                <div>11.8. 営業、宣伝、広告、勧誘、その他営利を目的とする行為（当社の認めたものを除きます。）、性行為やわいせ
                    つな行為を目的とする行為、面識のない第三者との出会いや交際を目的とする行為、他のお客様に対する嫌がらせや
                    誹謗中傷を目的とする行為、その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為 </div>
                <div>11.9. 反社会的勢力に対する利益供与その他の協力行為 </div>
                <div>11.10. 宗教活動または宗教団体への勧誘行為 </div>
                <div>11.11. 他人の個人情報、登録情報、利用履歴情報等を、不正に収集、開示または提供する行為 </div>
                <div>11.12. 本サービスのサーバやネットワークシステムに支障を与える行為、BOT、チートツール、その他の技術的手
                    段を利用して本サービスを不正に操作する行為、本サービスの不具合を意図的に利用する行為、同様の質問を必要以
                    上に繰り返す等、当社に対し不当な問い合わせまたは要求をする行為、その他当社による本サービスの運営または他
                    のお客様による本サービスの利用を妨害し、これらに支障を与える行為 </div>
                <div>11.13. 不当な目的または態様でのリバースエンジニアリング、逆アセンブルを行う行為、その他の方法でソースコ
                    ードを解読する行為</div>
                <div>11.14. 11.1.から11.13.までのいずれかに該当する行為を援助または助長する行為</div>
                <div>11.15. 11.1.から11.14.までに定めるもののほか、当社が不適当と合理的に判断した行為</div>
                <div className="terms-bold">
                    14. お客様の責任</div>
                <div>
                    14.1. お客様は、お客様ご自身の責任において本サービスを利用するものとし、本サービスにおいて行った一切の行
                    為およびその結果について一切の責任を負うものとします。</div>
                    
                    <div>
                    14.2. 当社は、お客様が以下のいずれかに該当する場合または該当するおそれがある場合、あらかじめお客様に通知
                    することなく、本サービスの全部または一部の利用の停止、アカウントの停止または削除、本サービスに関するお客
                    様と当社との間の契約（本規約に基づく契約を含みます。以下同じ。）の解除その他の当社が必要かつ適切と合理的
                    に判断する措置を講じることができます。
                    </div>
                   
                    <div>(1) 適用のある法令または本規約もしくは個別利用条件に違反した場合</div>
                   <div> (2) 反社会的勢力の構成員またはその関係者である場合</div>
                    <div>(3) 風説の流布、偽計、威力その他の不正な手段を用いて当社の信用を毀損する場合</div>
                   <div> (4) 差押え、仮差押えもしくは競売の申立てを受けた場合、または破産手続開始、民事再生手続開始もしくはこれら
                    に類する手続の開始の申立てがあった場合その他お客様の信用不安が発生したと当社が合理的に判断した場合</div>
                    <div>(5) (1)から(4)までに定めるもののほか、お客様との信頼関係が失われた場合その他お客様への本サービスの提供が適
                    切でないと当社が合理的に判断した場合</div>
                    <div>(6) 未成年者、成年被後見人、被保佐人または被補助人のいずれかであり、法定代理人、後見人、保佐人または補助人の同意等を得ていなかった場合</div>
                   <div> (7) (1)から(4)までに定めるもののほか、お客様との信頼関係が失われた場合その他お客様への本サービスの提供が適切でないと当社が合理的に判断した場合</div>
                   <div> 14.3. お客様は、本サービスを利用したことに起因して（当社がかかる利用を原因とするクレームを第三者より受け
                    た場合を含みます。）、当社が直接的または間接的に何らかの損害（弁護士費用の負担を含みます。）を被った場合
                    、当社の請求にしたがって直ちにこれを賠償しなければなりません。</div>
                
                <div className="terms-bold">
                    15. 非保証</div>
                <div>

                    当社は、本サービス（本コンテンツを含みます。）に関する瑕疵（セキュリティ等に関する欠陥、エラーやバグ、権
                    利侵害を含みます。）がないこと、ならびに安全性、信頼性、正確性、完全性、有効性および特定の目的への適合性
                    を明示的にも黙示的にも保証していません。当社は、お客様に対して、かかる瑕疵を除去して本サービスを提供する
                    義務を負いません。
                </div>
                <div className="terms-bold">

                    16. 当社の免責
                </div>
                <div>

                    16.1. 当社は、当社の故意または重過失に起因する場合を除き、本サービスに起因してお客様に生じたあらゆる損害
                    について一切の責任を負いません。ただし、本サービスに関するお客様と当社との間の契約が消費者契約法に定める
                    消費者契約（以下「消費者契約」といいます。）となる場合、当社は、当社の過失（重過失を除きます。）による債
                    務不履行責任または不法行為責任については、逸失利益その他の特別の事情によって生じた損害を賠償する責任を負
                    わず、通常生ずべき損害の範囲内で、かつ、有料である本サービスにおいてはお客様から当該損害が発生した月に受
                    領した当該本サービスの利用料の額を上限として損害賠償責任を負うものとします。
                </div>
                <div>
                    16.2. 当社の重過失に起因してお客様に損害が生じた場合、当社は、逸失利益その他の特別の事情によって生じた損
                    害を賠償する責任を負わず、通常生ずべき損害の範囲内で、かつ、有料である本サービスにおいてはお客様から当該
                    損害が発生した月に受領した当該本サービスの利用料の額を上限として損害賠償責任を負うものとします。ただし、
                    本サービスに関するお客様と当社との間の契約が消費者契約に該当する場合はこの限りではありません。
                </div>
                <div className="terms-bold">

                    17. 本規約と法令の関係
                </div>
                <div>

                    本規約の規定が本サービスに関するお客様と当社との間の契約に適用される消費者契約法その他の法令に反するとさ
                    れる場合、当該規定は、その限りにおいて、お客様との契約には適用されないものとします。ただし、この場合でも
                    、本規約の他の規定の効力に影響しないものとします。
                </div>
                <div className="terms-bold">

                    18. 連絡方法
                </div>
                <div>

                    18.1. 本サービスに関する当社からお客様への連絡は、本サービスまたは当社ウェブサイト内の適宜の場所への掲示
                    その他、当社が適当と判断する方法により行います。</div>
                <div>
                    18.2. 本サービスに関するお客様から当社への連絡は、本サービスまたは当社ウェブサイト内の適宜の場所に設置す
                    るお問い合わせフォームの送信または当社が指定する方法により行っていただきます。</div>

                <div className="terms-bold">

                    19. 準拠法、裁判管轄
                </div>
                <div className="terms-bottom">

                    本規約は日本語を正文とし、その準拠法は日本法とします。本サービスに起因または関連してお客様と当社との間に
                    生じた紛争については東京地方裁判所を第一審の専属的合意管轄裁判所とします。
                </div>
                <div className="terms-right">

                    以上
                </div>

                <div className="terms-bottom1">

                最終更新日：2022年8月１日
                </div>

                {/* <Footer/> */}
            </div>


        </>
    )
}