import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import LandingPage from "./components/LandingPage";
import Privacypolicy from "./components/Privacypolicy";
import { Terms } from "./components/Terms";
import { FAQ } from "./components/FAQ";

export default function App() {

  return (
    <BrowserRouter>
      <Routes>
        
      <Route index element={<LandingPage/>} />
      <Route path="/privacypolicy/jp" element={<Privacypolicy />} />
        <Route path="/privacypolicy/en" element={<Privacypolicy />} />
        
        <Route path="/terms/jp" element={<Terms />} />
        <Route path="/terms/en" element={<Terms />} />
        <Route path="/faq/jp" element={<FAQ />} />
        <Route path="/faq/en" element={<FAQ />} />

      </Routes>
    </BrowserRouter>
  );
}



